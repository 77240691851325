import { forwardRef } from "react"
import { workCont } from "../constants";


const Works = forwardRef((props, ref) => {

    return (
      <section className="min-h-screen max-w-[1920px] pt-10" ref={ref}>
        <div className="flex items-center flex-row mb-3"><h2 className="text-h2 whitespace-nowrap">My Works</h2><hr className="w-screen border-t ml-4 -mr-16 max-sm:-mr-5 border-black" /></div>
        <div className="mx-auto">
          <div className="grid grid-cols-2 max-lg:grid-cols-1 gap-4">
            {workCont.map((workCont, index)=>(
              <div key={index} className="relative group transition-all duration-500  min-h-[360px] max-md:min-h-[250px] overflow-hidden h-full max-md:rounded-md rounded-lg shadow-black/20 shadow-lg">
                <img src={workCont.image} 
                alt={workCont.title}
                width='100%'
                height={600}
                className=" bg-black h-full object-cover"
                />
                <div className="absolute w-[30%] min-w-[min-content] overflow-visible group-hover:whitespace-normal whitespace-nowrap max-sm:h-16 max-sm:justify-center h-24 max-sm:hover:h-min group max-sm:rounded-b-md max-sm:rounded-t-none max-sm:group-hover:items-start max-sm:group-hover:justify-center max-sm:flex max-sm:w-full max-sm:py-0 max-sm:px-3 max-sm:m-0  group-hover:w-full group-hover:h-full flex-col hover:flex-center group-hover:my-0 hover:rounded-none group-hover:mx-0 transition-all duration-700 backdrop-blur-md bottom-0   mx-6 my-6 rounded-2xl  p-4 px-6  bg-[#000000]/50 group-hover:flex-center group-hover:justify-center">
                <p className="text-white/90 group-hover:opacity-0 group-hover:absolute opacity-100 group-hover:duration-0 duration-[2000ms] max-sm:text-ps truncate uppercase text-p">{workCont.subtitle}</p>
                <h2 className="text-white/100 group-hover:duration-0 group-hover:absolute  group-hover:opacity-0 duration-[2000ms] max-sm:text-h2mob  text-h2 ">{workCont.title}</h2>
                <div className="group-hover:opacity-100 max-sm:items-start transition-opacity duration-100 group-hover:duration-[3000ms] absolute group-hover:static flex-col items-center flex  w-[80%] max-sm:w-[90%] opacity-0">
                     <p className="text-white/90   max-sm:text-ps truncate uppercase text-p">{workCont.subtitle}</p>
                    <h2 className="text-white/100  max-sm:text-h2mob  text-h2 ">{workCont.title}</h2>
                    <p className="text-white/100 max-sm:text-left whitespace-normal text-center max-sm:text-ps mt-3  group-hover:flow-root text-p">{workCont.description}</p>
                    <a className="text-white  underline   group-hover:flow-root text-p  max-sm:text-ps  mt-8" href={`/${workCont.title.replace(/\s+/g, '').toLowerCase()}`}>View Project</a>
                </div>
               
             </div>
                
              </div>
            ))}
          </div>
        </div>

      </section>
    );
  });

Works.displayName = "Works"

export default Works