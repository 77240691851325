import introvid from "/assets/videos/introvid.mp4";
import introvidwebm from "/assets/videos/introvid.webm";
import sitelogo from "/assets/images/edwwwinlogo.svg";
import introimg from "/assets/images/introimg.svg";
import arrowdown from "/assets/images/arrowdown.svg";
import sidearrow from "/assets/images/sidearrow.svg";
import arrowsubmit from "/assets/images/arrowsubmit.svg";
import mailSent from "/assets/images/mailsucces.svg";
import footerlogo from "/assets/images/footerlogo.svg";

export const introvideo = introvid;
export const introvideowebm = introvidwebm;
export const Sitelogo = sitelogo;
export const Introimg = introimg;
export const Arrowdown = arrowdown;
export const SideArrow = sidearrow;
export const Arrowsubmit = arrowsubmit;
export const Footerlogo = footerlogo;
export const Mailsent = mailSent;
