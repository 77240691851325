import { FaEnvelope, FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";

export const workCont = [
	{
		image: "/assets/images/workimages/kings.jpg",
		title: "Kings Holidays Munnar",
		subtitle: "Website Design",
		description:
			"A visually stunning, responsive front page for a luxury resort website, designed to showcase the resort’s amenities, accommodations, and services. Focused on easy navigation, high-quality visuals, and an intuitive user experience across desktop and mobile devices for potential guests.",
		id: 1,
	},
	{
		image: "/assets/images/workimages/bamboo.jpg",
		title: "Bamboo Nest Kochi",
		subtitle: "Website Design",
		description:
			"Helped a luxury riverside homestay enhance their online presence by designing and developing a responsive website. Improved user experience, highlighted their unique location and amenities, and streamlined booking, resulting in increased engagement and bookings across all devices.",
		id: 2,
	},
	{
		image: "/assets/images/workimages/worksample.png",
		title: "Project Application ",
		subtitle: "About Project",
		description:
			"A dynamic web application that connects local artists with community members, showcasing portfolios, facilitating collaborations, and promoting events, fostering creativity and engagement within the artistic community.",
		id: 3,
	},
	{
		image: "/assets/images/workimages/worksample.png",
		title: "Project Application ",
		subtitle: "About Project",
		description:
			"A dynamic web application that connects local artists with community members, showcasing portfolios, facilitating collaborations, and promoting events, fostering creativity and engagement within the artistic community.",
		id: 4,
	},
	{
		image: "/assets/images/workimages/worksample.png",
		title: "Project Application ",
		description:
			"A dynamic web application that connects local artists with community members, showcasing portfolios, facilitating collaborations, and promoting events, fostering creativity and engagement within the artistic community.",
		subtitle: "About Project",
		id: 5,
	},
	{
		image: "/assets/images/workimages/worksample.png",
		title: "Project Application ",
		description:
			"A dynamic web application that connects local artists with community members, showcasing portfolios, facilitating collaborations, and promoting events, fostering creativity and engagement within the artistic community.",
		subtitle: "About Project",
		id: 6,
	},
];

export const services = [
	{ title: "WEB DEV." },
	{ title: "ECOMMERCE DEV." },
	{ title: "BRANDING" },
	{ title: "DESIGNING" },
	{ title: "CMS" },
	{ title: "APP DEV." },
];

export const socialIcons = [
	{
		name: "instagram",
		icon: FaInstagram,
		url: "https://www.instagram.com/edwintonyjames/",
	},
	{
		name: "linkedin",
		icon: FaLinkedinIn,
		url: "https://www.linkedin.com/in/edwintonyjames/?_l=en_US",
	},
	{
		name: "mail",
		icon: FaEnvelope,
		url: "mailto:edwintonyjames.msg@gmail.com",
	},
];
