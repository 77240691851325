import {  useLayoutEffect, useRef } from "react";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Works from "./components/Works";
import Footer from "./components/Footer";
import gsap from 'gsap';
import Preloader from "./components/Preloader";
import { ReactLenis } from "lenis/react"

const App = () => {
  const worksRef = useRef(null);
  const containerRef = useRef(null);

  
  
  useLayoutEffect(()=>{
      
      if (document.readyState !== 'loading') {
        gsap.fromTo(containerRef.current, { opacity: 0 }, { opacity: 1, duration: 1 });
       
        gsap.fromTo("#preloader", { opacity: 1 }, { opacity: 0, display: 'none', duration: 1 });}
     

      
  },[])
  
  return (
    <ReactLenis root>
<Preloader id="preloader"/>  <main  ref={containerRef} id="main" >
          <Navbar />
          <Hero scrollToWorks={() => worksRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })} />
          <Works ref={worksRef}/>
          <Footer />
        </main>
    
    </ReactLenis>
  );
};

export default App;
