
import { useEffect, useRef } from "react";
import { Arrowdown, Introimg } from "../utils";
import gsap from 'gsap';
import PropTypes from "prop-types";
const Hero = ({scrollToWorks}) => {
  const introRef = useRef(null);
  const aboutBtnRef = useRef(null);

  useEffect(() => {
    const introElement = introRef.current; // Access the DOM element via ref
    const introTxt = introRef.current

    const introTxtwords = introTxt.textContent.split(" "); 
    introTxt.innerHTML = introTxtwords.map((introTxtword)=>(introTxtword === 'Designer' || introTxtword ==='Developer') ? `<span class='text-h1 mr-[3px] font-semibold introWordAnim max-sm:text-h1mob'>${introTxtword}</span>` :`<span class='text-h1 mr-[3px] introWordAnim max-sm:text-h1mob'>${introTxtword}</span>` ).join(" ")

    const handleMouseMove = (e) => {
      const x = e.clientX - 100;
      const y = e.clientY - 20;

      // Directly apply the clientX and clientY for positioning
      gsap.to(aboutBtnRef.current, {
        left: x, // Directly set the button's left position to the cursor's X position
        top: y,  // Directly set the button's top position to the cursor's Y position
        duration: 0.6,
        ease: "power3.out",
        transform: 'translate(-150%, -150%)', // Ensure the button is centered on the cursor
      });
    };

    const handleMouseEnter = () => {
      gsap.to(aboutBtnRef.current, {
        duration: 0.3,
        ease: "power3.out",
      });
    };

    
    // Add event listeners to introRef element
    introElement.addEventListener("mousemove", handleMouseMove);
    introElement.addEventListener("mouseenter", handleMouseEnter);

    if (document.readyState !== "loading") {
    gsap.timeline()
    .fromTo('#introImg', 
      { y: -50, opacity:0 }, 
      { y: 0, duration: 1, opacity:1,ease:"power3.out",delay:1, stagger:1.8 }, // Translate y takes 2 seconds
      "<" // Start at the same time as the previous animation 
      )
      .fromTo([".introWordAnim", "#btnNxt"], {opacity:0,y:-30}, {opacity:1,stagger:0.18,y:0, duration:2, delay:1, }, "<")
  
    }
    // Cleanup event listeners on unmount
    return () => {
      introElement.removeEventListener("mousemove", handleMouseMove);
      introElement.removeEventListener("mouseenter", handleMouseEnter);
    };

    

  }, []);

  return (
    <section  style={{height:'calc(100vh - 100px)'}} className="flex flex-center flex-col ">
       
       <img id="introImg" className="w-[420px] mb-20 max-sm:w-60" src={Introimg} alt="" />
        <div ref={introRef} id="introTxt" className=" max-w-[705px] mb-20  text-center">
             {<h1 className="text-h1 max-sm:text-h1mob">Hi, Im Edwin, a <span className="font-semibold">Designer</span> and <span className="font-semibold">Developer</span> with a passion for crafting user-friendly, innovative digital solutions.</h1>}
        </div>
        <button ref={aboutBtnRef} className="pr-5 backdrop-blur opacity-0 hover:opacity-100 pl-5 absolute pt-2 pb-2 border  rounded-[30px] drop-shadow-md hover:text-black transition duration-700" >About Me</button>
       <button onClick={scrollToWorks} id="btnNxt">
        <img className="hover:translate-y-4 transition-all duration-700 max-md:animate-bounce" src={Arrowdown} alt="" />
       </button>
        
        </section>
  )
}
Hero.propTypes = {
  scrollToWorks: PropTypes.func.isRequired, // Ensure scrollToWorks is a required function prop
};


export default Hero